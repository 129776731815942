  
    import { Component, Mixins, Ref } from "vue-property-decorator";
    import ETable from "@/components/ETable.vue";
    import RichText from "@/components/rich-text.vue";
  
    import TablePagination from "@/mixins/tablePagination";
    import Resize from "@/mixins/resize";

    import { tongueShareConfig } from "@/api/tongue";
    import { ElForm } from "element-ui/types/form";

    import { formatTextarea } from "@/utils/common"
  
    @Component({
      components: {
        ETable,
        RichText
      }
    })
    export default class Corporeity extends Mixins(Resize, TablePagination) {
      @Ref("formRef") formRef !: ElForm;

      tableData: Array<any> = [];
      tableLoading = false;
      columns = [
        { label: "体质名称", prop: "symptomName" },
        { label: "舌色", prop: "tongueColor" },
        { label: "苔色", prop: "mossColor" },
        { label: "舌形", prop: "tongueShaped" },
        { label: "苔质", prop: "coatingNature" },
        { label: "舌下络脉", prop: "sublingualCollaterals" },
        { label: "形体特征", minWidth: "180", prop: "physicalCharacteristics" },
        { label: "形体特征代表人物（男）", minWidth: "180", prop: "roleOfMan"},
        { label: "形体特征代表人物（女）", minWidth: "180", prop: "roleOfWoman"},
        { label: "心理特征", minWidth: "180", prop: "psychology"},
        { label: "心理特征代表人物（男）", minWidth: "180",prop: "mentalityOfMan"},
        { label: "心理特征代表人物（女）", minWidth: "180", prop: "mentalityOfWoman"},
        { label: "湿气程度%", minWidth: "100", prop: "congestionLevel"},
        { label: "瘀堵程度%", minWidth: "100", prop: "moistureLevel"},
    ]

      activeName = "first";
  
      mounted() {
        this.windowResize(220);
        this.getData();
      }

      formatTextarea(content) {
        return formatTextarea(content)
      }
  
      getData() {
        tongueShareConfig().then((res) => {
          this.tableData = res.data;
        })
      }
  
    }
  